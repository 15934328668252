import * as React from 'react';

import LayoutDefault from '../layouts/default';
import TextHeader from '../components/textHeader';

// markup
const PricingPage = () => {
  return (
    <>
      <LayoutDefault>
        <TextHeader
          title="Pricing"
          desc="Our product, priced to fit your specific needs"
        />
        <div>
          <section className="bg-light py-10">
            <div className="container px-5">
              <div className="text-center mb-5">
                <h2>Simple Pricing</h2>
                <p className="lead">Easy pricing means no suprises.</p>
              </div>
              <div className="row gx-5">
                <div className="col-lg-4 mb-5 mb-lg-0">
                  <div className="card pricing h-100">
                    <div className="card-body p-5">
                      <div className="text-center">
                        <div className="badge bg-light text-dark rounded-pill badge-marketing badge-sm">
                          Free
                        </div>
                        <div className="pricing-price">
                          <sup>$</sup>0
                          <span className="pricing-price-period">/mo</span>
                        </div>
                      </div>
                      <ul className="fa-ul pricing-list">
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">1 user</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Community support</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-circle text-gray-200" />
                          </span>
                          Style customizer
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-circle text-gray-200" />
                          </span>
                          Custom components
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-circle text-gray-200" />
                          </span>
                          Expanded utilities
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-circle text-gray-200" />
                          </span>
                          Third-party integration
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-circle text-gray-200" />
                          </span>
                          Layout options
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-5 mb-lg-0">
                  <div className="card pricing h-100">
                    <div className="card-body p-5">
                      <div className="text-center">
                        <div className="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">
                          Standard
                        </div>
                        <div className="pricing-price">
                          <sup>$</sup>
                          19
                          <span className="pricing-price-period">/mo</span>
                        </div>
                      </div>
                      <ul className="fa-ul pricing-list">
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Up to 15 users</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">
                            Email and live chat support
                          </span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Style customizer</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Custom components</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Expanded utilities</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">
                            Third-party integration
                          </span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Layout options</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card pricing h-100">
                    <div className="card-body p-5">
                      <div className="text-center">
                        <div className="badge bg-light text-dark rounded-pill badge-marketing badge-sm">
                          Enterprise
                        </div>
                        <p className="card-text py-10">
                          We offer discounts for larger organizations. Contact
                          us to inquire about enterprise level plans!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <a className="btn btn-primary fw-500" href="#!">
                  Get Started
                </a>
              </div>
            </div>
          </section>
          <hr className="my-0" />
          <section className="bg-light py-10">
            <div className="container px-5">
              <div className="text-center mb-5">
                <h2>One plan, no hassle</h2>
                <p className="lead">Easy pricing means no suprises.</p>
              </div>
              <div className="row g-0 align-items-center">
                <div className="col-lg-6 mb-5 mb-lg-0">
                  <div className="card pricing">
                    <div className="card-body p-5">
                      <div className="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">
                        Standard
                      </div>
                      <div className="pricing-price">
                        <sup>$</sup>
                        19
                        <span className="pricing-price-period">/mo</span>
                      </div>
                      <ul className="fa-ul pricing-list">
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Up to 15 users</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">
                            Email and live chat support
                          </span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Style customizer</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Custom components</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Expanded utilities</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">
                            Third-party integration
                          </span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Layout options</span>
                        </li>
                      </ul>
                    </div>
                    <div className="card-footer px-5">
                      <a
                        className="d-flex align-items-center justify-content-between"
                        href="#!"
                      >
                        Get Started!
                        <i className="fas fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card pricing pricing-right bg-gray-800">
                    <div className="card-body p-5">
                      <div className="badge bg-transparent-light badge-marketing rounded-pill badge-sm">
                        Customized
                      </div>
                      <p className="card-text py-5">
                        Need a customized plan? Contact us directly and we can
                        work with you to craft a plan that fits your company's
                        specific needs.
                      </p>
                    </div>
                    <div className="card-footer bg-gray-900 px-5">
                      <a
                        className="text-white-50 d-flex align-items-center justify-content-between"
                        href="#!"
                      >
                        Contact Sales
                        <i className="fas fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr className="my-0" />
          <section className="bg-light py-10">
            <div className="container px-5">
              <div className="text-center mb-5">
                <h2>Simple Pricing</h2>
                <p className="lead">Easy pricing means no suprises.</p>
              </div>
              <div className="row g-0 align-items-center">
                <div className="col-lg-4 mb-5 mb-lg-0">
                  <div className="card pricing pricing-left">
                    <div className="card-body p-5">
                      <div className="text-center">
                        <div className="badge bg-light text-dark rounded-pill badge-marketing badge-sm">
                          Free
                        </div>
                        <div className="pricing-price">
                          <sup>$</sup>0
                          <span className="pricing-price-period">/mo</span>
                        </div>
                      </div>
                      <ul className="fa-ul pricing-list">
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">1 user</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Community support</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-circle text-gray-200" />
                          </span>
                          Style customizer
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-circle text-gray-200" />
                          </span>
                          Custom components
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-circle text-gray-200" />
                          </span>
                          Expanded utilities
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-circle text-gray-200" />
                          </span>
                          Third-party integration
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-circle text-gray-200" />
                          </span>
                          Layout options
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-5 mb-lg-0">
                  <div className="card pricing py-4 z-1">
                    <div className="card-body p-5">
                      <div className="text-center">
                        <div className="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">
                          Standard
                        </div>
                        <div className="pricing-price">
                          <sup>$</sup>
                          19
                          <span className="pricing-price-period">/mo</span>
                        </div>
                      </div>
                      <ul className="fa-ul pricing-list">
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Up to 15 users</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">
                            Email and live chat support
                          </span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Style customizer</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Custom components</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-circle text-gray-200" />
                          </span>
                          Expanded utilities
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-circle text-gray-200" />
                          </span>
                          Third-party integration
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-circle text-gray-200" />
                          </span>
                          Layout options
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-5 mb-lg-0">
                  <div className="card pricing pricing-right">
                    <div className="card-body p-5">
                      <div className="text-center">
                        <div className="badge bg-secondary-soft rounded-pill badge-marketing badge-sm text-secondary">
                          Premium
                        </div>
                        <div className="pricing-price">
                          <sup>$</sup>
                          29
                          <span className="pricing-price-period">/mo</span>
                        </div>
                      </div>
                      <ul className="fa-ul pricing-list">
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Unlimited users</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">
                            Email and live chat support
                          </span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Style customizer</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Custom components</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Expanded utilities</span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">
                            Third-party integration
                          </span>
                        </li>
                        <li className="pricing-list-item">
                          <span className="fa-li">
                            <i className="far fa-check-circle text-teal" />
                          </span>
                          <span className="text-dark">Layout options</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <a className="btn btn-primary fw-500" href="#!">
                  Get Started
                </a>
              </div>
            </div>
          </section>
          <hr className="my-0" />
          <section className="bg-light pt-10">
            <div className="container px-5">
              <div className="row gx-5 align-items-center mb-10">
                <div className="col-lg-6">
                  <div className="h4 fw-normal">Ready to get started?</div>
                  <p className="lead mb-0 text-gray-500">
                    Get in touch or create an account.
                  </p>
                </div>
                <div className="col-lg-6 text-lg-end">
                  <a className="btn btn-primary fw-500 me-3 my-2" href="#!">
                    Contact Sales
                  </a>
                  <a className="btn btn-white fw-500 shadow my-2" href="#!">
                    Create Account
                  </a>
                </div>
              </div>
              <div className="row gx-5">
                <div className="col-lg-6 z-1 mb-5 mb-lg-n5">
                  <a className="card text-decoration-none h-100 lift" href="#!">
                    <div className="card-body py-5">
                      <div className="d-flex align-items-center">
                        <div className="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0">
                          <i data-feather="activity" />
                        </div>
                        <div className="ms-4">
                          <h5 className="text-primary">
                            Work smarter, not harder
                          </h5>
                          <p className="card-text text-gray-600">
                            Learn more about how our product can save you time
                            and effort in the long run!
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-6 z-1 mb-10 mb-lg-n5">
                  <a className="card text-decoration-none h-100 lift" href="#!">
                    <div className="card-body py-5">
                      <div className="d-flex align-items-center">
                        <div className="icon-stack icon-stack-xl bg-secondary text-white flex-shrink-0">
                          <i data-feather="code" />
                        </div>
                        <div className="ms-4">
                          <h5 className="text-secondary">
                            Built for developers
                          </h5>
                          <p className="card-text text-gray-600">
                            Our components, utilities, and layouts are built
                            with developers in mind.
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="svg-border-waves text-dark">
              {/* Wave SVG Border*/}
              <svg
                className="wave"
                style={{ pointerEvents: 'none' }}
                fill="currentColor"
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 1920 75"
              >
                <defs>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        '\n                                        .a {\n                                            fill: none;\n                                        }\n                                        .b {\n                                            clip-path: url(#a);\n                                        }\n                                        .d {\n                                            opacity: 0.5;\n                                            isolation: isolate;\n                                        }\n                                    ',
                    }}
                  />
                </defs>
                <clipPath id="a">
                  <rect className="a" width={1920} height={75} />
                </clipPath>
                <g className="b">
                  <path
                    className="c"
                    d="M1963,327H-105V65A2647.49,2647.49,0,0,1,431,19c217.7,3.5,239.6,30.8,470,36,297.3,6.7,367.5-36.2,642-28a2511.41,2511.41,0,0,1,420,48"
                  />
                </g>
                <g className="b">
                  <path
                    className="d"
                    d="M-127,404H1963V44c-140.1-28-343.3-46.7-566,22-75.5,23.3-118.5,45.9-162,64-48.6,20.2-404.7,128-784,0C355.2,97.7,341.6,78.3,235,50,86.6,10.6-41.8,6.9-127,10"
                  />
                </g>
                <g className="b">
                  <path
                    className="d"
                    d="M1979,462-155,446V106C251.8,20.2,576.6,15.9,805,30c167.4,10.3,322.3,32.9,680,56,207,13.4,378,20.3,494,24"
                  />
                </g>
                <g className="b">
                  <path
                    className="d"
                    d="M1998,484H-243V100c445.8,26.8,794.2-4.1,1035-39,141-20.4,231.1-40.1,378-45,349.6-11.6,636.7,73.8,828,150"
                  />
                </g>
              </svg>
            </div>
          </section>
        </div>
      </LayoutDefault>
    </>
  );
};

export default PricingPage;
